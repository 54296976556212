import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from './useFormContext';

export const useUpdateStepNames = () => {
  const { t } = useTranslation();
  const { form } = useFormContext();

  const stepNames = [
    t('ProductAndName'),
    t('ShipmentDimension'),
    form.values.letterWindowType ? t(`step-two.${form.values.letterWindowType}`) : t('ShipmentType'),
    form.values.letterSenderPlacementType
      ? `${t(`step-three.${form.values.letterFrankingPlacementType}`)} & ${t(`step-three.${form.values.letterSenderPlacementType}`)}`
      : form.values.letterFrankingPlacementType
        ? t(`step-three.${form.values.letterFrankingPlacementType}`)
        : t('FrankingAndSenderPosition'),
    t('SelectPDF'),
    t('UploadPDF'),
  ];

  return stepNames;
};
