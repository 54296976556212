import { useTranslation } from 'react-i18next';
import { Accordion, Alert, Flex, Grid, List, Loader, Paper, Text } from '@mantine/core';
import './print.css';
import { CheckIconWithCircle } from '../../components/Icons/CheckIconWithCircle';
import { DefaultButton } from '../../components/Buttons/Buttons';
import { Area, CheckResult, SectionResult } from '../../api/requests';
import { StyledAlert, StyledAlertStatus } from '../../components/Alert/StyledAlert';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { PdfIcon } from '../../components/Icons/PdfIcon';
import { useFormContext } from '../../hooks/useFormContext';
import { SuccessIcon } from '../../components/Icons/SuccessIcon';
import { ErrorHelpButton } from '../../components/Buttons/ErrorHelpButton';
import { useProjectControllerServiceCloseProject, useProjectControllerServiceGetPreview } from '../../api/queries';
import { basePath } from '../../config/basePath';
import { useAuthenticationManager } from '../../hooks/useAuthenticationManager';
import { ButtonBar } from '../../components/Buttons/ButtonBar';
import { useGoToDashboard } from '../../hooks/useGoToDashboard';
import { useAnalyticResults } from '../../hooks/useAnalyticResults';
import { ImportantInstructions } from '../../components/ImportantInstructions/ImportantInstructions';
import { useNavigateWithSearchParams } from '../../hooks/useNavigateWithSearchParams';
import html2pdf from 'html2pdf.js';

export const PleaseCheck = ({ color }: { color?: string }) => {
  const { t } = useTranslation();
  return (
    <Text style={{ fontWeight: 'bold', color: color ?? 'var(--BRAND-DHL-Red)' }}>{t('step-five.please-check')}</Text>
  );
};

const MalwareAlert = () => {
  const { t } = useTranslation();
  return (
    <Alert variant="light" color="red" title={t('step-five.malwareAlert.title')}>
      <div>{t('step-five.malwareAlert.message')}</div>
      <div>{t('step-five.malwareAlert.causeTitle')}</div>
      <div>{t('step-five.malwareAlert.causeDescription')}</div>
      <ul>
        <li>{t('step-five.malwareAlert.cause1')}</li>
        <li>{t('step-five.malwareAlert.cause2')}</li>
        <li>{t('step-five.malwareAlert.cause3')}</li>
      </ul>
      <div>{t('step-five.malwareAlert.solution')}</div>
    </Alert>
  );
};

const RenderResult = ({ hasError }: { hasError: boolean }) => {
  const { t } = useTranslation();
  return hasError ? (
    <Flex align="center" gap="5px">
      <PleaseCheck />
      <WarningIcon />
    </Flex>
  ) : (
    <Flex align="center" gap="5px">
      <Text style={{ fontWeight: 'bold', color: 'var(--BASIC-Black)' }}>{t('step-five.ok')}</Text>
      <CheckIconWithCircle size={20} color="var(--SECONDARY-Green-O-900)" />
    </Flex>
  );
};

const ErrorList = ({ checks }: { checks: CheckResult[] }) => {
  const { i18n } = useTranslation();
  return (
    <List mt="5px" listStyleType="none" withPadding>
      {checks
        .filter((check) => check.result === 'ERROR')
        .map((check, index) => (
          <List.Item key={index}>
            <Flex align="center">
              <Text fw={700}>{(i18n.language.includes('en') && check.englishName) || check.name}:</Text>
            </Flex>
            <List withPadding listStyleType="initial">
              {(check.messages ?? []).map((message, messageIndex) => (
                <List.Item key={messageIndex} pr="20px">
                  <Flex>
                    <Text>{(i18n.language.includes('en') && message.messageEnglish) || message.message}</Text>
                    <ErrorHelpButton errorCode={message.code} />
                  </Flex>
                </List.Item>
              ))}
            </List>
          </List.Item>
        ))}
    </List>
  );
};

const fetchFileWithAuth = async (url: string, token?: string): Promise<Blob> => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch file: ${response.statusText}`);
  }

  return response.blob();
};

const fetchPdfWithAuth = async (url: string, token?: string): Promise<string> => {
  const blob = await fetchFileWithAuth(url, token);
  return URL.createObjectURL(blob);
};

const Image = ({ imageUrl }: { imageUrl: string | undefined }) => {
  const { t } = useTranslation();

  return (
    <img
      src={imageUrl}
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        boxShadow: '2px 2px 4px var(--GRAY-ALPHA-Gray-A-200)',
        border: '1px solid var(--GRAY-OPAC-Gray-O-400)',
      }}
      alt={t('step-five.preview')}
    />
  );
};

const MarkedImage = ({ area, imageUrl }: { area?: Area; imageUrl: string | undefined }) => {
  if (!area) {
    return null;
  }

  const { x, y } = area;

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <Image imageUrl={imageUrl} />
      <div
        style={{
          position: 'absolute',
          left: `${x.from * 100}%`,
          top: `${y.from * 100}%`,
          width: `${(x.to - x.from) * 100}%`,
          height: `${(y.to - y.from) * 100}%`,
          border: '2px dotted red',
          boxSizing: 'border-box',
        }}
      ></div>
    </div>
  );
};

const SectionItem = ({
  section,
  imageUrl,
  isLastItem,
}: {
  section: SectionResult;
  imageUrl: string | undefined;
  isLastItem: boolean;
}) => {
  const hasError = section.checks.some((check) => check.result === 'ERROR');
  const { i18n } = useTranslation();
  return (
    <>
      <Accordion.Item value={(i18n.language.includes('en') && section.englishName) || section.name} key={section.name}>
        <Accordion.Control
          disabled={!hasError}
          style={{ opacity: !hasError ? 1 : undefined }}
          chevron={hasError ? undefined : <div></div>}
        >
          <Flex justify="space-between">
            <Flex style={{ fontWeight: 'bold' }}>
              {(i18n.language.includes('en') && section.englishName) || section.name}
            </Flex>
            <Flex>
              <RenderResult hasError={hasError} />
            </Flex>
          </Flex>
        </Accordion.Control>
        <Accordion.Panel>
          {hasError && (
            <>
              <Alert
                style={{ color: 'var(--BRAND-DHL-Red)', background: 'var(--SECONDARY-Red-O-100)' }}
                icon={<WarningIcon />}
              >
                <PleaseCheck color="black" />
                <ErrorList checks={section.checks} />
              </Alert>
              <MarkedImage area={section.area} imageUrl={imageUrl} />
            </>
          )}
        </Accordion.Panel>
      </Accordion.Item>
      {hasError && !isLastItem ? <div className="page-break"></div> : null}
    </>
  );
};

const ResultButtons = () => {
  const { t } = useTranslation();
  const closeProject = useProjectControllerServiceCloseProject();
  const authenticationManager = useAuthenticationManager();
  const { form, isCheckNegative, isCheckPositive, isProjectClosed, refetch } = useFormContext();

  const generatePdf = () => {
    const element = document.querySelector('.printable') as HTMLElement;
    const opt = {
      margin: [10, 10, 10, 10],
      filename: `${t('pdf.title')} ${form.values.projectNumber}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      pagebreak: { before: '.page-break' },
    };

    html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          // Add header
          pdf.text(`${t('step-five.process-number')} ${form.values.projectNumber}`, 10, 7);
          // Add footer
          pdf.text(
            `${t('pdf.page')} ${i} ${t('pdf.of')} ${totalPages}`,
            10, // Adjusted x-coordinate to move the footer further to the left
            pdf.internal.pageSize.getHeight() - 5, // Adjusted y-coordinate to move the footer further to the top
          );
        }

        // Save PDF
        // pdf.save(opt.filename);

        // Open PDF in a new tab
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
      });
  };

  const openAttestationInNewTab = async () => {
    const token = await authenticationManager.getAccessToken(120);
    const url = `${basePath}/api/project/${form.values.projectNumber}/attestation`;
    const fileUrl = await fetchPdfWithAuth(url, token);
    window.open(fileUrl, '_blank');
  };

  const closeProjectAndDownloadAttestation = async () => {
    if (isProjectClosed) {
      await openAttestationInNewTab();
    } else {
      await closeProject.mutateAsync({ projectNumber: form.values.projectNumber });
      await refetch();
      await openAttestationInNewTab();
    }
  };

  if (isCheckNegative) {
    return (
      <DefaultButton data-testid="print-button" onClick={generatePdf}>
        {t('step-five.print')}
      </DefaultButton>
    );
  }

  if (isCheckPositive) {
    return (
      <>
        {form.values.checkType === 'FULL_CHECK' &&
          (isProjectClosed ? (
            <DefaultButton data-testid="download-button" onClick={() => openAttestationInNewTab()}>
              {t('download')}
            </DefaultButton>
          ) : (
            <DefaultButton data-testid="download-button" onClick={() => closeProjectAndDownloadAttestation()}>
              {t('complete')}
            </DefaultButton>
          ))}
        <Flex mt={10}>
          <ImportantInstructions
            infoText={t('step-five.important')}
            listItems={[
              { details: form.values.checkType === 'FULL_CHECK' ? t('step-five.info') : t('step-five.info-layout') },
            ]}
            hideBulletpoints={true}
          />
        </Flex>
      </>
    );
  }
};

const ResultAlert = () => {
  const { t } = useTranslation();
  const { form, isCheckNegative, isCheckPositive, isCheckPositiveOrNegative } = useFormContext();
  const icon = isCheckNegative ? <WarningIcon /> : isCheckPositive ? <SuccessIcon /> : <PdfIcon />;
  const status = isCheckNegative
    ? StyledAlertStatus.error
    : isCheckPositive
      ? StyledAlertStatus.success
      : StyledAlertStatus.info;
  const title = isCheckNegative
    ? t('step-five.alert.not-automation-capable.title')
    : isCheckPositive
      ? t('step-five.alert.automation-capable.title')
      : form.values.userFileName;

  return (
    isCheckPositiveOrNegative && (
      <StyledAlert
        data-testid="result-alert"
        style={{ marginBottom: '20px' }}
        icon={icon}
        title={title}
        status={status}
        showLoader={false}
      >
        {isCheckPositive && <span>{t('step-five.alert.automation-capable')}</span>}
      </StyledAlert>
    )
  );
};

export const StepSix = () => {
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();
  const { t } = useTranslation();

  const { isInternal } = useAuthenticationManager();

  const goToDashboard = useGoToDashboard();
  const { form, isMalicious, isCheckPositiveOrNegative, isProjectClosed, isQueryComplete } = useFormContext();
  const isAnalyticsEnabled = isCheckPositiveOrNegative && isQueryComplete;
  const { sections, openedSections, setOpenedSections, isAnalyticsLoading } = useAnalyticResults(isAnalyticsEnabled);
  const projectNumber = form.values.projectNumber;
  const isPreviewEnabled = !!projectNumber && isCheckPositiveOrNegative && isQueryComplete;
  const queryInfo = useProjectControllerServiceGetPreview({ projectNumber }, undefined, {
    enabled: isPreviewEnabled,
  });
  const imageUrl = queryInfo.data;
  const isLoading = isAnalyticsLoading || queryInfo.isLoading;
  const hasPreviewImageError = queryInfo.isError;

  const goToStep5 = () => {
    form.setFieldValue('userFile', undefined);
    form.setFieldValue('userFileName', '');
    navigate(`/wizard/${projectNumber}/5`);
  };

  const isNextButtonDisabledStepFive = !isCheckPositiveOrNegative && !isMalicious;

  const showPreviewImage = () => {
    if (hasPreviewImageError) {
      return <div>{t('step-five.preview-image-error')}</div>;
    }
    if (isCheckPositiveOrNegative) {
      return <Image imageUrl={imageUrl} />;
    }
    return null;
  };

  return (
    <>
      <Paper shadow="sm" radius="md" withBorder p="xl">
        <Grid gutter={40}>
          <Grid.Col>
            <Grid>
              <Grid.Col>
                <h2>{t('step-five.specify.result')}</h2>
              </Grid.Col>
              {isMalicious && (
                <Grid.Col>
                  <MalwareAlert />
                </Grid.Col>
              )}
              {!isLoading ? (
                <>
                  <Grid.Col>{showPreviewImage()}</Grid.Col>
                  <Grid.Col>
                    <ResultAlert />
                  </Grid.Col>
                  <Grid.Col>
                    <Flex className="printable" direction="column">
                      <Accordion
                        chevronPosition="left"
                        styles={{
                          chevron: {
                            color: 'var(--BRAND-DHL-Red)',
                            width: '35px',
                            svg: {
                              width: 'auto!important',
                              height: 'auto!important',
                            },
                          },
                        }}
                        multiple
                        value={openedSections}
                        onChange={setOpenedSections}
                      >
                        {sections?.map((section, index) => (
                          <SectionItem
                            section={section}
                            key={index}
                            imageUrl={imageUrl}
                            isLastItem={sections.length - 1 === index}
                          />
                        ))}
                      </Accordion>
                    </Flex>
                  </Grid.Col>
                  <ResultButtons />
                </>
              ) : (
                <Grid.Col style={{ display: 'flex', justifyContent: 'center' }}>
                  <Loader color="var(--BRAND-DHL-Red)" />
                </Grid.Col>
              )}
            </Grid>
          </Grid.Col>
        </Grid>
      </Paper>
      <ButtonBar
        onNext={goToStep5}
        onCancel={goToDashboard}
        cancelButtonLabel={isProjectClosed ? t('backToDashboard') : t('cancel')}
        nextButtonLabel={t('retry')}
        showOnlyCancelButton={isProjectClosed || isInternal}
        isNextButtonDisabled={isNextButtonDisabledStepFive}
      />
    </>
  );
};
