// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {

  body * {
    visibility: hidden;
  }

  .printable,
  .printable * {
    visibility: visible;
  }

  .printable {
    margin-top: 50px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .page-break {
    page-break-before: always;
    margin-top: 50px
  }
}`, "",{"version":3,"sources":["webpack://./src/views/steps/print.css"],"names":[],"mappings":"AAAA;;EAEE;IACE,kBAAkB;EACpB;;EAEA;;IAEE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB;EACF;AACF","sourcesContent":["@media print {\n\n  body * {\n    visibility: hidden;\n  }\n\n  .printable,\n  .printable * {\n    visibility: visible;\n  }\n\n  .printable {\n    margin-top: 50px;\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n  }\n\n  .page-break {\n    page-break-before: always;\n    margin-top: 50px\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
