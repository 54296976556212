import { rem } from '@mantine/core';

interface CheckboxIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const CheckboxIcon = ({ size, style, ...others }: CheckboxIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="12px"
    width="12px"
    viewBox="0 0 12 12"
    fill="none"
    style={{ width: rem(size), height: rem(size), ...style }}
    {...others}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.6L5.6 9L4 10.6L2.4 9L0 6.6L1.6 5L4 7.4L10.4 1L12 2.6Z"
      fill="white"
    />
  </svg>
);
