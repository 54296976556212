import { Container, Flex, Loader, PaperProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { WizardStepper } from './WizardStepper/WizardStepper';
import React from 'react';
import { PageHelpButton } from './Buttons/PageHelpButton';
import { useProductQueryInfoContext } from '../hooks/useProductInfo';
import { useUpdateStepNames } from '../hooks/useStepNames';
import { DevTools } from './DevTools/DevTools';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { useWizardGuard } from '../hooks/useWizardGuard';

interface WizardPageContainerProps extends PaperProps {
  children: React.ReactNode;
}

export const WizardPageContainer = ({ children }: WizardPageContainerProps) => {
  const { isLoading, isError } = useProductQueryInfoContext();
  const { t } = useTranslation();

  const stepNames = useUpdateStepNames();

  useScrollToTop();
  useWizardGuard();

  if (isLoading) {
    return (
      <Container size="xl" style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
        <Loader color="var(--BRAND-DHL-Red)" />
      </Container>
    );
  } else if (isError) {
    return (
      <Container size="xl" style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
        <h2>{t('network.error')}</h2>
      </Container>
    );
  } else {
    return (
      <Container size="xl">
        <DevTools />
        <Flex justify="center">
          <h1>{t('automationCheck')}</h1>
        </Flex>
        <Flex style={{ justifyContent: 'flex-end', width: '100%', minHeight: '36px' }}>
          <PageHelpButton />
        </Flex>
        <WizardStepper steps={stepNames} />
        <BackgroundDiv>{children}</BackgroundDiv>
      </Container>
    );
  }
};

const BackgroundDiv = styled.div`
  padding: 27px;
  margin-bottom: 27px;
  background-color: var(--GRAY-OPAC-Gray-O-100);
  border-radius: 8px;
`;
