export const errorCodeToPageAnchorMap: { [key: string]: string } = {
  ADDRESS_DURCHSCHUSS: 'lesezone-a876g5.html#durchschuesse',
  AUFSCHRIFT_LEFT_JUSTIFIED: 'lesezone-a876g5.html#linksbuendig',
  ADDRESS_NOT_FOUND: 'lesezone-a876g5.html#erwartete-anschrift',
  ADDRESS_NEGATIVE: 'lesezone-a876g5.html#erwartete-anschrift',
  ADDRESS_WORD_SPACING_UNMEASURABLE: 'lesezone-a876g5.html#wortabstaende-nicht-messbar',
  ADDRESS_WORD_SPACING: 'lesezone-a876g5.html#wortabstaende',
  ADDRESS_CAP_HEIGHT: 'lesezone-a876g5.html#versalhoehen',
  ADDRESS_CHAR_SPACING: 'lesezone-a876g5.html#zeichenabstand',
  ASPECT_RATIO: 'allgemeine-angaben-k87b3f.html#seitenverhaeltnis',
  AUFSCHRIFT_DV_MISSING: 'lesezone-a876g5.html#frankiervermerk-fehlt',
  AUFSCHRIFT_DV_SIZE: 'lesezone-a876g5.html#matrix-code',
  AUFSCHRIFT_DV_POSITION: 'lesezone-a876g5.html#code-position',
  INDIVIDUAL_MARK_MISSING: 'lesezone-a876g5.html#kundenmerkmal',
  AUFSCHRIFT_PREMIUMADDRESS: 'lesezone-a876g5.html#premiumadress',
  AUFSCHRIFT_LESEZONE: 'lesezone-a876g5.html#aufschrift-lesezone',
  FRANKIERZONE_BOUNDARY_VIOLATED: 'frankierzone-b7hgf4.html#frankiervermerk',
  AUFSCHRIFT_RUHEZONE: 'lesezone-a876g5.html#ruhezone-aufschrift',
  AUFSCHRIFT_FIELD_SIZE: 'lesezone-a876g5.html#aufschriftenfeld',
  AUFSCHRIFT_FIELD_INNER_MARGIN: 'lesezone-a876g5.html#aufschrift-abstand',
  OTHER_ADDRESSES: 'lesezone-a876g5.html#lesezone-angaben',
  AUFSCHRIFT_UNDERLINE: 'lesezone-a876g5.html#lesezone-angaben',
  NO_BASISFORMAT: 'allgemeine-angaben-k87b3f.html#basisformat',
  DIALOGPOST_NO_ENVELOPE_LANDSCAPE: 'allgemeine-angaben-k87b3f.html#querformat',
  DIALOGPOST_NO_ENVELOPE_LENGTH: 'allgemeine-angaben-k87b3f.html#mindestmass-laenge',
  DIALOGPOST_NO_ENVELOPE_WIDTH: 'allgemeine-angaben-k87b3f.html#mindestmass-breite',
  CODIERZONE: 'codierzone-987hbv.html#gestaltungselemente',
  PDF_DIMENSIONS: 'allgemeine-angaben-k87b3f.html#pdf-abmessungen',
  FRANKIERZONE_NEGATIVE: 'frankierzone-b7hgf4.html#hintergrund-dunkel',
  FRANKIERZONE_ALPHANUMERIC: 'frankierzone-b7hgf4.html#alphanumerisch',
  FRANKIERZONE_RECTS: 'frankierzone-b7hgf4.html#gestaltungselemente',
  FRANKIERZONE_WELLE_SIZE: 'frankierzone-b7hgf4.html#originalgroesse',
  FRANKIERZONE_WELLE_DISTORTED: 'frankierzone-b7hgf4.html#verzerrt',
  FRANKIERZONE_WELLE_ROTATED: 'frankierzone-b7hgf4.html#rotiert',
  FRANKIERZONE_WELLE_ILLEGAL_TEXT: 'frankierzone-b7hgf4.html#frankierwelle-text',
  FRANKIERZONE_WELLE_RUHEZONE: 'frankierzone-b7hgf4.html#ruhezone',
  FRANKIERZONE_WELLE_CUSTOMER_IMAGE_SIZE: 'frankierzone-b7hgf4.html#kundenmotiv-masse',
  FRANKIERZONE_WELLE_CUSTOMER_IMAGE_BASELINE: 'frankierzone-b7hgf4.html#ausrichtung',
  FRANKIERZONE_WELLE_CUSTOMER_IMAGE_OVERLAP: 'frankierzone-b7hgf4.html#ueberlappen',
  FRANKIERZONE_WELLE_CUSTOMER_IMAGE_MULTIPLE: 'frankierzone-b7hgf4.html#mehrere-motive',
  FRANKIERZONE_WELLE_CUSTOMER_IMAGE_NUMBERS: 'frankierzone-b7hgf4.html#zahlenwerte',
  FRANKIERZONE_WELLE_CUSTOMER_IMAGE_CODES: 'frankierzone-b7hgf4.html#codes',
  FRANKIERZONE_WELLE_WRONG_BRANDING: 'frankierzone-b7hgf4.html#welle-branding',
  PDF_PAGES: 'allgemeine-angaben-k87b3f.html#pdf-seiten',
  AUFSCHRIFT_SENDER_NOT_FOUND: 'lesezone-a876g5.html#absender-fehlt',
  AUFSCHRIFT_SENDER_TOO_LARGE: 'lesezone-a876g5.html#anschrift-zu-gross',
  AUFSCHRIFT_SENDER_MULTILINE: 'lesezone-a876g5.html#absender-einzeilig',
  SENDER_ZONE_SENDER_NOT_FOUND: 'absenderzone-123abc.html#keine-absenderangabe',
  TRENNSTRICH_ADRESS_GAP: 'lesezone-a876g5.html#trennstrich-abstand',
  TRENNSTRICH_LENGTH: 'lesezone-a876g5.html#trennstrich-laenge',
  TRENNSTRICH_MULTIPLE: 'lesezone-a876g5.html#trennstrich-mehrere',
  TRENNSTRICH_SIZE: 'lesezone-a876g5.html#trennstrich-masse',
  TRENNSTRICH_MARGIN_TOP: 'lesezone-a876g5.html#trennstrich-abstand-oben',
  TRENNSTRICH_MARGIN_BOTTOM: 'lesezone-a876g5.html#trennstrich-abstand-unten',
  TRENNSTRICH_MARGIN_RIGHT: 'lesezone-a876g5.html#trennstrich-abstand-rechts',
  TRENNSTRICH_RUHEZONE: 'lesezone-a876g5.html#trennstrich-ruhezone',
  PDF_UNREADABLE: 'allgemeine-angaben-k87b3f.html#pdf-lesbar',
  PDF_FONTS: 'allgemeine-angaben-k87b3f.html#pdf-schriftarten',
  WINDOW_NOT_FOUND: 'lesezone-a876g5.html#fenster-nicht-gefunden',
  ADDITIONAL_WINDOW_LOCATION: 'lesezone-a876g5.html#weitere-fenster',
  ADDRESS_WINDOW_COLOR: 'lesezone-a876g5.html#fenster-zu-dunkel',
  ADDRESS_WINDOW_POSITION: 'lesezone-a876g5.html#fenster-plazierung',
  ADDRESS_WINDOW_SIZE: 'lesezone-a876g5.html#fenster-groesse',
  PANORAMIC_WINDOW_POSITION: 'lesezone-a876g5.html#panoramafenster',
  PANORAMIC_WINDOW_FRANKING_ZONE: 'lesezone-a876g5.html#panoramafenster',
  DIALOGPOST_NO_ENVELOPE_AUFSCHRIFT_LOCATION: 'lesezone-a876g5.html#aufschrift-umhuellung',
  ADDRESS_WINDOW_RUHEZONE: 'lesezone-a876g5.html#ruhezone-fenster',
  AUFSCHRIFT_SENDER_ALIGNMENT: 'lesezone-a876g5.html#absender-block',
  TRENNSTRICH_NOT_FOUND: 'lesezone-a876g5.html#trennstrich-suche',
  ONLY_DELIVER_TO_COMPANIES_DISCLAIMER_NOT_FOUND: 'lesezone-a876g5.html#verteilhinweis',
  ONLY_DELIVER_TO_COMPANIES_DISCLAIMER_LEFT_ALIGNMENT_ERROR: 'lesezone-a876g5.html#verteilhinweis-verortung',
  AUFSCHRIFT_PREMIUMADDRESS_NOT_ALLOWED: 'lesezone-a876g5.html#premiumadress-product',
  AUFSCHRIFT_FRANKING_MULTIPLE: 'lesezone-a876g5.html#frankiervermerk-matrixcode',
  AUFSCHRIFT_REDUCED_FRANKING_NOT_ALLOWED: 'lesezone-a876g5.html#frankiervermerk-aendern',
  AUFSCHRIFT_DV_NOT_ALLOWED: 'lesezone-a876g5.html#frankierart',
  AUFSCHRIFT_FRANKING_MISSING: 'lesezone-a876g5.html#frankierung-fehlt',
  AUFSCHRIFT_REDUCED_FRANKING_MISSING: 'lesezone-a876g5.html#frankierung-aufschrift',
  AUFSCHRIFT_PREMIUMADDRESS_P_SIZE: 'lesezone-a876g5.html#premiumadress-groesse',
  AUFSCHRIFT_REDUCED_FRANKING_PRODUCT: 'lesezone-a876g5.html#frankiervermerk-product',
  AUFSCHRIFT_REDUCED_FRANKING_SIZE: 'lesezone-a876g5.html#frankiervermerk-groesse',
  AUFSCHRIFT_REDUCED_FRANKING_LOCATION: 'lesezone-a876g5.html#frankiervermerk-position',
};
