import { Trans, useTranslation } from 'react-i18next';
import { Flex, Grid, List, ListItem, Text, Paper, Box, CloseButton, Textarea } from '@mantine/core';
import { SingleFileUpload } from '../../components/SingleFileUpload/SingleFileUpload';
import './print.css';
import { InfoIcon } from '../../components/Icons/InfoIcon';
import { ButtonBar } from '../../components/Buttons/ButtonBar';
import { useCheckProject } from '../../hooks/useCheckProject';
import { useFormContext } from '../../hooks/useFormContext';
import { useEffect } from 'react';
import { useGoToDashboard } from '../../hooks/useGoToDashboard';
import { useNavigateWithSearchParams } from '../../hooks/useNavigateWithSearchParams';
import { useUploadDocument } from '../../hooks/useUploadDocument';
import { useDomain } from '../../hooks/useEnv';
import { useTranslatedListItems } from '../../hooks/useTranslatedListItems';
import { InputCompact } from '../../components/Input/InputCompact';
import { CheckboxDefault } from '../../components/Checkbox/Checkbox';
import { useUpdateProject } from '../../hooks/useUpdateProject';

const sampleAdressUrl = '/geschaeftskunden/portal/kundendesktop/online-hilfe/automationscheck/aufschrift-j4zfg9.html';

export const InfoBoxStepFive = () => {
  const { t } = useTranslation();
  const { domain } = useDomain();
  const { i18n } = useTranslation();

  const listItems = useTranslatedListItems('step-four.infobox.list');

  return (
    <Flex
      style={{
        borderLeft: '1px solid var(--GRAY-OPAC-Gray-O-400)',
        paddingLeft: '20px',
        paddingRight: '20px',
        height: '100%',
      }}
    >
      <Flex direction={'column'} gap={10}>
        <InfoIcon color="var(--BASIC-Black)"></InfoIcon>
        <Text fw={700}>{t('step-four.infobox.title')}</Text>

        <List>
          {listItems.map((item: string, index: number) => (
            <ListItem key={index}>
              <Trans
                components={{
                  b: <b />,
                  l: (
                    <a href={`${domain}${i18n.language}${sampleAdressUrl}`} target="_blank" rel="noreferrer">
                      Automationsfähige Briefsendungen
                    </a>
                  ),
                }}
              >
                {item}
              </Trans>
            </ListItem>
          ))}
        </List>
        <Text>{t('step-four.infobox.footer')}</Text>
      </Flex>
    </Flex>
  );
};

export const MoreImportantInstructionsStep4 = () => {
  const { t } = useTranslation();
  const { domain } = useDomain();
  const { i18n } = useTranslation();
  return (
    <Flex
      direction="column"
      mt="md"
      style={{
        backgroundColor: 'var(--BRAND-DHL-Red)',
        borderRadius: '8px',
        padding: '14px 20px',
        alignItems: 'flex-start',
        color: 'var(--BASIC-White)',
      }}
    >
      <Text>
        <Trans
          components={{
            b: <b />,
            l: (
              <a
                style={{ color: 'var(--BASIC-White)' }}
                href={`${domain}${i18n.language}${sampleAdressUrl}`}
                target="_blank"
                rel="noreferrer"
              >
                Beispieladressen
              </a>
            ),
          }}
        >
          {t('step-four.important-instructions.recipientaddress')}
        </Trans>
      </Text>
      <Text>{t('step-four.important-instructions.recipientaddress2')}</Text>
    </Flex>
  );
};

export const ImportantInstructionsStep4 = () => {
  const { t } = useTranslation();
  return (
    <Flex
      direction="column"
      style={{
        backgroundColor: 'var(--BRAND-Postyellow-30p)',
        borderRadius: '8px',
        padding: '14px 20px',
        alignItems: 'flex-start',
      }}
    >
      <Flex
        direction="row"
        gap={10}
        style={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <InfoIcon color="var(--BASIC-Black)"></InfoIcon>
        <Text fw={700}>{t('step-four.important-instructions.title')}</Text>
      </Flex>
      <Flex direction="row" style={{ padding: '10px 25px' }}>
        <List listStyleType="none">
          <ListItem key={0}>
            <Text style={{ fontWeight: 700 }}>{t('step-four.important-instructions.dataprotection.title')}</Text>
            <List listStyleType="initial">
              <ListItem key={2}>
                <Text>{t('step-four.important-instructions.dataprotection.details.0')}</Text>
              </ListItem>
              <ListItem key={3}>
                <Text>{t('step-four.important-instructions.dataprotection.details.1')}</Text>
              </ListItem>
            </List>
          </ListItem>
        </List>
      </Flex>
    </Flex>
  );
};

export const StepFive = () => {
  const { t } = useTranslation();
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();
  const { form } = useFormContext();
  const { checkProject, isCheckSuccess, isProjectQueryRunning, isProjectStateDone, resetCheckQueryInfo } =
    useCheckProject();
  const { updateProject } = useUpdateProject();

  const goToDashboard = useGoToDashboard();
  const { uploadDocument, isUploadDocumentPending } = useUploadDocument();

  const handleNextClick = async () => {
    await updateProject(form.values.projectNumber, {
      partialAddressRecipient: form.values.partialAddressRecipient || undefined,
      onlyDeliverToCompanies: form.values.onlyDeliverToCompanies,
      checkType: form.values.checkType,
    });
    await checkProject();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    form.setFieldValue('checkType', isChecked ? 'LAYOUT_CHECK' : 'FULL_CHECK');
  };

  useEffect(() => {
    if (isCheckSuccess && isProjectStateDone) {
      resetCheckQueryInfo();
      navigate(`/wizard/${form.values.projectNumber}/6`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckSuccess, isProjectStateDone]);

  const hasUserFile = form.values.userFile !== undefined;

  const isPostwurfspezial = form.values.productType === 'POSTWURFSPEZIAL';
  const isDialogpost = form.values.productType === 'DIALOGPOST';
  const isNextButtonEnabledForDialogpost = isDialogpost && hasUserFile;
  const isNextButtonEnabledForPostwurfspezial =
    isPostwurfspezial &&
    hasUserFile &&
    (form.values.checkType === 'LAYOUT_CHECK' || form.values.partialAddressRecipient);
  const isNextButtonEnabled =
    (isNextButtonEnabledForDialogpost || isNextButtonEnabledForPostwurfspezial) && !isUploadDocumentPending;

  return (
    <>
      <Paper shadow="sm" radius="md" withBorder p="xl">
        <Grid gutter={40}>
          <Grid.Col span={9}>
            <Grid>
              <Grid.Col>
                <h2>{t('step-four.specify.select-pdf')}</h2>
              </Grid.Col>
              <Grid.Col>
                <ImportantInstructionsStep4 />
                <MoreImportantInstructionsStep4 />
              </Grid.Col>
              <Grid.Col>
                <Box>
                  <SingleFileUpload
                    uploadDocument={(projectNumber: string, userFile: File) => {
                      uploadDocument(projectNumber, userFile);
                    }}
                    isUploadDocumentPending={isUploadDocumentPending}
                  />
                </Box>
                <Box mt="xl">
                  <CheckboxDefault
                    label={t('step-four.layoutcheck')}
                    mb="10px"
                    mt="10px"
                    checked={form.values.checkType === 'LAYOUT_CHECK'}
                    onChange={handleCheckboxChange}
                  />
                </Box>
                {isPostwurfspezial && form.values.checkType !== 'LAYOUT_CHECK' && (
                  <Box mt="xl">
                    <Box>
                      <Text fw={700}>{t('step-four.postwurfspezial.title')}</Text>
                      <Text>{t('step-four.postwurfspezial.description')}</Text>
                      <Box>
                        <Textarea
                          required={true}
                          label={t('step-four.postwurfspezial.salutation')}
                          rightSectionPointerEvents="all"
                          key={form.key('partialAddressRecipient')}
                          {...form.getInputProps('partialAddressRecipient')}
                          minRows={2}
                          maxRows={2}
                          rightSection={
                            <CloseButton
                              aria-label={t('step-four.postwurfspezial.clear')}
                              onClick={() => form.setFieldValue('partialAddressRecipient', '')}
                              style={{ display: form.values.partialAddressRecipient ? undefined : 'none' }}
                            />
                          }
                        />
                      </Box>
                      <Box mt="lg">
                        <Text fw={700}>{t('step-four.postwurfspezial.only.for.companies.title')}</Text>
                        <Flex direction="column">
                          <CheckboxDefault
                            label={t('step-four.postwurfspezial.only.for.companies.checkbox.label')}
                            mt="10px"
                            mb="20px"
                            key={form.key('onlyDeliverToCompanies')}
                            {...form.getInputProps('onlyDeliverToCompanies', { type: 'checkbox' })}
                          />
                        </Flex>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={3}>
            <InfoBoxStepFive />
          </Grid.Col>
        </Grid>
      </Paper>
      <ButtonBar
        onNext={handleNextClick}
        onCancel={goToDashboard}
        cancelButtonLabel={t('cancel')}
        nextButtonLabel={t('next')}
        isLoading={isProjectQueryRunning}
        isNextButtonDisabled={!isNextButtonEnabled}
      />
    </>
  );
};
