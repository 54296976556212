import { ProjectVO } from '../../api/requests';
import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { DownloadFileIcon } from '../Icons/DownloadFileIcon';
import { useNavigateWithSearchParams } from '../../hooks/useNavigateWithSearchParams';

export const DownloadButton = (props: { record: ProjectVO }) => {
  const { record } = props;
  const { goToStep } = useNavigateWithSearchParams();
  const { t } = useTranslation();
  if (record.projectState !== 'CLOSED' && record.projectState !== 'DONE') {
    return null;
  }

  if (
    record.projectState === 'DONE' &&
    record.attestationState === 'SUCCEEDED' &&
    record.checkType === 'LAYOUT_CHECK'
  ) {
    return null;
  }

  return (
    <Flex style={{ alignItems: 'center' }}>
      <Tooltip
        withArrow
        arrowSize={10}
        label={
          record.attestationState === 'SUCCEEDED'
            ? t('dashboard.downloadCheckReport')
            : t('dashboard.downloadErrorReport')
        }
        style={{
          backgroundColor: 'var(--BASIC-Black)',
          color: 'var(--BASIC-White)',
        }}
      >
        <ActionIcon variant="default" onClick={goToStep(record.projectNumber, '6')} style={{ fontSize: '15px' }}>
          <DownloadFileIcon size="15" color="var(--BRAND-DHL-Red)" />
        </ActionIcon>
      </Tooltip>
    </Flex>
  );
};
