import { Container, Flex, Text } from '@mantine/core';
import { DataTable, DataTableColumn, DataTableSortStatus } from 'mantine-datatable';
import { useProjectControllerServiceGetProjects } from '../api/queries';
import 'mantine-datatable/styles.layer.css';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { attestationState, productType, projectState, ProjectVO } from '../api/requests';
import { DefaultButton } from '../components/Buttons/Buttons';
import { useQueryParams } from '../hooks/useQueryParams';
import { TFunction } from 'i18next';
import { MouseEvent, useEffect, useState } from 'react';
import { InputCompactWithSearchButton } from '../components/Input/InputCompact';
import { PageHelpButton } from '../components/Buttons/PageHelpButton';
import { ArrowDownSelect } from '../components/Select/StyledSelect';
import { DateInputStyled } from '../components/DateInput/DateInputStyled';
import dayjs from 'dayjs';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { ThreeDotsMenu } from '../components/ThreeDotsMenu';
import { useNavigateWithSearchParams } from '../hooks/useNavigateWithSearchParams';
import { useAuthenticationManager } from '../hooks/useAuthenticationManager';
import { useDomain } from '../hooks/useEnv';
import { DownloadButton } from '../components/Buttons/DownloadButton';
import { StartNewCheckButton } from '../components/Buttons/StartNewCheckButton';

const StyledDataTable = styled(DataTable<ProjectVO>)`
  .mantine-datatable-header {
    background-color: var(--GRAY-OPAC-Gray-O-400);
  }

  .mantine-Table-td p {
    font-size: 13px;
  }

  .mantine-datatable-pagination-text {
    display: none;
  }

  .mantine-datatable-pagination {
    flex-direction: row-reverse !important;
    background-color: var(--GRAY-OPAC-Gray-O-100);
  }

  .mantine-datatable-pagination-pages {
    margin-left: auto;
    margin-right: auto;
  }

  .mantine-Pagination-control {
    border: 0;
    font-weight: bold;
    --pagination-control-size: 33px;
    background-color: transparent;
  }

  .mantine-Pagination-control[data-active='true'] {
    background-color: var(--BRAND-DHL-Red);
  }

  .mantine-Pagination-control.mantine-active:first-of-type,
  .mantine-Pagination-control.mantine-active:last-of-type {
    color: var(--BRAND-DHL-Red);
  }

  .mantine-Table-tr:hover {
    background-color: var(--GRAY-OPAC-Gray-O-200);
  }

  .mantine-Table-td,
  .mantine-Table-th {
    padding: 7px 5px;
  }

  .mantine-datatable-header-cell-sortable-group {
    gap: 5px;
    --group-justify: flex-start !important;
  }

  .mantine-datatable-header-cell-sortable-group div {
    width: auto !important;
  }
`;

const isProjectValid = (validUntil: string | undefined) => {
  if (!validUntil) return false;
  const currentDate = new Date();
  const validUntilDate = new Date(validUntil);
  return validUntilDate > currentDate;
};

export const Dashboard = () => {
  const {
    page,
    setPage,
    pageSize,
    setPageSize,
    sortStatus,
    setSortStatus,
    productType,
    setProductType,
    name,
    setName,
    updatedAfter,
    setUpdatedAfter,
    updatedBefore,
    setUpdatedBefore,
    state,
    setState,
    attestationState,
    setAttestationState,
    createdByFamilyName,
    setCreatedByFamilyName,
    projectNumber,
    setProjectNumber,
    checkType,
    setCheckType,
  } = useQueryParams();
  const { goToStep } = useNavigateWithSearchParams();

  const { isInternal } = useAuthenticationManager();

  const mapColumnAccessorToSortBy = () => {
    switch (sortStatus.columnAccessor) {
      case 'name':
        return 'NAME';
      case 'projectState':
        return 'PROJECT_STATE';
      case 'productType':
        return 'PRODUCT_TYPE';
      default:
        return 'UPDATED';
    }
  };

  const getProjectQueryInfo = useProjectControllerServiceGetProjects({
    page,
    pageSize,
    productType: productType as productType,
    sortBy: mapColumnAccessorToSortBy(),
    sortDirection: sortStatus.direction.toLocaleUpperCase() as 'ASC' | 'DESC',
    name,
    updatedBefore: updatedBefore,
    updatedAfter: updatedAfter,
    state: state as projectState,
    attestationState: attestationState as attestationState,
    createdByFamilyName,
    projectNumber,
    checkType,
  });
  const { t, i18n } = useTranslation();
  const { domain } = useDomain();

  const getCheckAgainButton = (record: ProjectVO) => {
    if (record.projectState === 'DONE' && record.attestationState === 'FAILED') {
      return (
        <DefaultButton
          style={{ fontSize: '12px', height: '30px', minWidth: 'auto' }}
          onClick={goToStep(record.projectNumber, '5')}
        >
          {t('dashboard.checkAgain')}
        </DefaultButton>
      );
    }
    return null;
  };

  const getColor = (projectState: projectState, attestationState: string | undefined) => {
    switch (projectState) {
      case 'PENDING':
      case 'RUNNING':
        return 'orange';
      case 'FAILED':
        return 'red';
      case 'DONE':
        return attestationState === 'FAILED' ? 'red' : 'green';
      case 'CLOSED':
        return 'green';
      case 'EXPIRED':
        return 'grey';
      default:
        return 'black';
    }
  };

  const StatusIndicator = styled.div<{ color: string }>`
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-top: 0.2em;
    margin-right: 0.5em;
  `;

  const addWeeks = (updatedDate: Date, weeks: number) =>
    new Date(updatedDate.setDate(updatedDate.getDate() + weeks * 7));

  const renderProjectState = (row: ProjectVO, t: TFunction) => {
    const isDone = row.projectState === 'DONE';
    const isClosed = row.projectState === 'CLOSED';
    const isSucceeded = row.attestationState === 'SUCCEEDED';
    const isValid = isProjectValid(row.validUntil);
    const validUntilDate = row.validUntil ? new Date(row.validUntil) : null;
    const updatedDate = row.updated ? new Date(row.updated) : new Date();
    const deletionDate = row.updated ? addWeeks(updatedDate, 6) : null;

    const statusText = t(`${row.projectState}_${row.attestationState}_${row.checkType}`);
    const validUntilText = validUntilDate
      ? `${t('dashboard.validUntil')} ${t('intlDateTime', { val: validUntilDate })}`
      : '';
    const deletionText = deletionDate ? `${t('dashboard.deletion')} ${t('intlDateTime', { val: deletionDate })}` : '';
    const closedText = isValid
      ? `${t('dashboard.validUntil')} ${t('intlDateTime', { val: validUntilDate })}`
      : `${t('dashboard.on')} ${t('intlDateTime', { val: validUntilDate })}`;

    return (
      <Flex align="center" style={{ minHeight: '40px' }}>
        <Flex>
          <StatusIndicator
            color={getColor(row.projectState, row.attestationState)}
            data-testid={`StatusIndicator-${row.projectNumber}`}
          />
          {isDone ? (
            <Flex direction="column">
              <Text>{statusText}</Text>
              <Text style={{ fontSize: '12px' }}>{isSucceeded ? validUntilText : deletionText}</Text>
            </Flex>
          ) : isClosed ? (
            <Flex direction="column">
              <Text>{t(isValid ? row.projectState : 'EXPIRED')}</Text>
              <Text style={{ fontSize: '12px' }}>{closedText}</Text>
            </Flex>
          ) : (
            <Text>{t(row.projectState)}</Text>
          )}
        </Flex>
      </Flex>
    );
  };

  const columnsDefinition: DataTableColumn<ProjectVO>[] = [
    {
      accessor: 'projectNumber',
      title: t('dashboard.projectNumber'),
      width: 150,
      render: (row: ProjectVO) => <Text>{row.projectNumber}</Text>,
    },
    {
      accessor: 'name',
      title: t('dashboard.projectName'),
      width: 250,
      sortable: true,
      render: (row: ProjectVO) => <Text>{row.name}</Text>,
    },
    {
      accessor: 'projectState',
      title: t('dashboard.projectState'),
      width: 180,
      sortable: true,
      render: (row: ProjectVO) => renderProjectState(row, t),
    },
    {
      accessor: 'productType',
      title: t('dashboard.productType'),
      width: 100,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      render: (row: ProjectVO) => <Text>{t(row.productType!)}</Text>,
    },
    {
      accessor: 'basisFormat',
      title: t('dashboard.basisFormat'),
      width: 100,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      render: (row: ProjectVO) => <Text>{t(row.basisFormat!)}</Text>,
    },
    {
      accessor: 'createdByFamilyName',
      title: t('dashboard.name'),
      width: 150,
      render: (row: ProjectVO) => (
        <Text>
          {row.createdBy.givenName} {row.createdBy.familyName}
        </Text>
      ),
    },
    {
      accessor: 'updated',
      title: t('dashboard.updated'),
      width: 150,
      sortable: true,
      render: (row: ProjectVO) => (
        <Text>
          {row.updated
            ? t('intlDateTime', {
                val: new Date(row.updated),
                formatParams: {
                  val: {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  },
                },
              })
            : null}
        </Text>
      ),
    },
  ];

  if (!isInternal) {
    columnsDefinition.push({
      accessor: 'getCheckAgainButton',
      title: '',
      width: 120,
      render: (record: ProjectVO) => {
        return getCheckAgainButton(record);
      },
    });
  }

  columnsDefinition.push({
    accessor: 'getDownloadButton',
    title: '',
    width: 40,
    render: (record: ProjectVO) => {
      return <DownloadButton record={record} />;
    },
  });

  if (!isInternal) {
    columnsDefinition.push({
      accessor: 'threeDots',
      title: '',
      width: 40,
      render: (row: ProjectVO) => (
        <ThreeDotsMenu
          row={row}
          refetchProjects={() => {
            // if only one record is left on the last page, we need to go back one page
            if (getProjectQueryInfo.data?.data?.length === 1 && page > 0) {
              setPage(page - 1);
            }
            getProjectQueryInfo.refetch();
          }}
        />
      ),
    });
  }

  const projects = getProjectQueryInfo.data?.data ?? [];
  const totalRecords = getProjectQueryInfo.data?.totalItems ?? 0;

  const handlePageChange = (newPage: number) => {
    setPage(newPage - 1);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const handleSortChange = (sortStatusInput: DataTableSortStatus<ProjectVO>) => {
    setSortStatus({
      columnAccessor: sortStatusInput.columnAccessor,
      direction: sortStatus.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  const isEditable = (record: ProjectVO) => record.projectState === 'PENDING';

  const isDownloadable = (record: ProjectVO) => record.projectState === 'CLOSED' || record.projectState === 'DONE';

  const handleCellClick = (params: { event: MouseEvent; record: ProjectVO; index: number; columnIndex: number }) => {
    if (params.columnIndex > 6) return;

    if (isEditable(params.record)) {
      goToStep(params.record.projectNumber, '1')();
    }

    if (isDownloadable(params.record)) {
      goToStep(params.record.projectNumber, '6')();
    }
  };

  const productTypeValues: { value: productType | ''; label: string }[] = [
    { value: 'DIALOGPOST', label: t('DIALOGPOST') },
    { value: 'POSTWURFSPEZIAL', label: t('POSTWURFSPEZIAL') },
  ];

  type ProjectState =
    | 'PENDING'
    | 'FAILED'
    | 'RUNNING'
    | 'DONE_FAILED'
    | 'DONE_SUCCEEDED'
    | 'LAYOUT_SUCCEEDED'
    | 'EXPIRED';
  const statusValues: { value: ProjectState; label: string }[] = [
    { value: 'PENDING', label: t('PENDING') },
    { value: 'FAILED', label: t('FAILED') },
    { value: 'RUNNING', label: t('RUNNING') },
    { value: 'DONE_FAILED', label: t('DONE_FAILED') },
    { value: 'DONE_SUCCEEDED', label: t('DONE_SUCCEEDED_FULL_CHECK') },
    { value: 'LAYOUT_SUCCEEDED', label: t('DONE_SUCCEEDED_LAYOUT_CHECK') },
    { value: 'EXPIRED', label: t('EXPIRED') },
  ];

  const [projectState, setProjectState] = useState<ProjectState | undefined>(undefined);
  useEffect(() => {
    if (state === 'PENDING' || state === 'FAILED' || state === 'RUNNING' || state === 'EXPIRED') {
      setProjectState(state as ProjectState);
    }
    if (state === undefined && attestationState === 'SUCCEEDED' && checkType === 'FULL_CHECK') {
      setProjectState('DONE_SUCCEEDED');
    }
    if (state === undefined && attestationState === 'SUCCEEDED' && checkType === 'LAYOUT_CHECK') {
      setProjectState('LAYOUT_SUCCEEDED');
    }
    if (state === undefined && attestationState === 'FAILED') {
      setProjectState('DONE_FAILED');
    }
  }, [state, attestationState, checkType]);

  useScrollToTop();

  return (
    <Container size="xl">
      <h1>{t('dashboard.my-checks')}</h1>
      <Flex style={{ justifyContent: 'flex-end', width: '100%', minHeight: '36px' }}>
        <PageHelpButton page="dashboard-d4s32s.html" />
      </Flex>
      <Flex direction="column">
        <Text>
          <span style={{ fontWeight: 700 }}>{t('dashboard.description.important')}</span>
          {t('dashboard.description.details-1')}
        </Text>
        <Text>
          {t('dashboard.description.details-2')}
          <a
            href={`${domain}${i18n.language}/geschaeftskunden/portal/kundendesktop/online-hilfe/automationscheck/aufschrift-j4zfg9.html`}
            target="_blank"
            rel="noreferrer"
          >
            {t('dashboard.description.details-3')}
          </a>
        </Text>
      </Flex>
      <Flex
        direction="column"
        style={{
          backgroundColor: 'var(--GRAY-OPAC-Gray-O-100)',
          padding: '20px 30px',
          margin: '20px 0px',
          alignItems: 'flex-end',
        }}
      >
        <Flex style={{ width: '100%', justifyContent: 'space-between' }}>
          <Flex style={{ flexDirection: 'column' }}>
            <Flex style={{ flexDirection: 'row', alignItems: 'top', gap: '10px' }}>
              <InputCompactWithSearchButton
                data-testid="search-input"
                label={t('dashboard.search')}
                styles={{
                  wrapper: {
                    margin: 0,
                  },
                  root: {
                    width: '350px',
                  },
                }}
                onSearch={(value) => {
                  setPage(0);
                  setName(value || undefined);
                }}
                searchValue={name}
              />

              <DateInputStyled
                data-testid="start-date-input"
                label={t('dashboard.startDate')}
                value={updatedAfter ? dayjs(updatedAfter).toDate() : null}
                valueFormat={t('dashboard.dateFormat')}
                onChange={(date) => {
                  setPage(0);
                  date ? setUpdatedAfter(dayjs(date).format('YYYY-MM-DD')) : setUpdatedAfter(undefined);
                }}
              />
              <DateInputStyled
                data-testid="end-date-input"
                label={t('dashboard.endDate')}
                value={updatedBefore ? dayjs(updatedBefore).toDate() : null}
                valueFormat={t('dashboard.dateFormat')}
                onChange={(date) => {
                  setPage(0);
                  date ? setUpdatedBefore(dayjs(date).format('YYYY-MM-DD')) : setUpdatedBefore(undefined);
                }}
              />
              <InputCompactWithSearchButton
                data-testid="search-input-porject-number"
                label={t('dashboard.projectNumber')}
                styles={{
                  wrapper: {
                    margin: 0,
                  },
                  root: {
                    width: '200px',
                  },
                }}
                onSearch={(value) => {
                  setPage(0);
                  setProjectNumber(value || undefined);
                }}
                searchValue={projectNumber}
              />
            </Flex>
            <Flex style={{ flexDirection: 'row', alignItems: 'top', gap: '10px' }}>
              <InputCompactWithSearchButton
                data-testid="search-input-family-name"
                label={t('dashboard.createdByFamilyName')}
                styles={{
                  wrapper: {
                    margin: 0,
                  },
                  root: {
                    width: '350px',
                  },
                }}
                onSearch={(value) => {
                  setPage(0);
                  setCreatedByFamilyName(value || undefined);
                }}
                searchValue={createdByFamilyName}
              />
              <ArrowDownSelect
                label={t('dashboard.product')}
                data={productTypeValues}
                scrollAreaProps={{
                  type: 'auto',
                }}
                onChange={(value, _) => {
                  setPage(0);
                  setProductType(value ?? undefined);
                }}
                value={productType}
              />
              <ArrowDownSelect
                data-testid="status-select"
                label={t('dashboard.state')}
                scrollAreaProps={{
                  type: 'auto',
                }}
                data={statusValues}
                onChange={(value, _) => {
                  const projectState = value as ProjectState;
                  setProjectState(projectState);
                  setPage(0);
                  switch (projectState) {
                    case 'PENDING':
                    case 'FAILED':
                    case 'RUNNING':
                    case 'EXPIRED':
                      setState(value ?? undefined);
                      setCheckType(undefined);
                      setAttestationState(undefined);
                      break;
                    case 'DONE_FAILED':
                      setState(undefined);
                      setCheckType(undefined);
                      setAttestationState('FAILED');
                      break;
                    case 'DONE_SUCCEEDED':
                      setState(undefined);
                      setAttestationState('SUCCEEDED');
                      setCheckType('FULL_CHECK');
                      break;
                    case 'LAYOUT_SUCCEEDED':
                      setState(undefined);
                      setAttestationState('SUCCEEDED');
                      setCheckType('LAYOUT_CHECK');
                      break;
                    default:
                      setCheckType(undefined);
                      setState(undefined);
                      setAttestationState(undefined);
                  }
                }}
                value={projectState}
              />
            </Flex>
          </Flex>
          {isInternal ? null : <StartNewCheckButton />}
        </Flex>
      </Flex>
      <StyledDataTable
        data-testid="check-table"
        records={projects}
        fetching={getProjectQueryInfo.isFetching}
        idAccessor={(record) => record.projectNumber}
        columns={columnsDefinition}
        totalRecords={totalRecords}
        page={page + 1}
        onPageChange={handlePageChange}
        recordsPerPage={pageSize}
        onRecordsPerPageChange={handlePageSizeChange}
        recordsPerPageOptions={[5, 10, 20]}
        recordsPerPageLabel={t('dashboard.recordsPerPage')}
        sortStatus={sortStatus}
        onSortStatusChange={handleSortChange}
        textSelectionDisabled
        onCellClick={isInternal ? undefined : handleCellClick}
      />
    </Container>
  );
};
