import { useTranslation } from 'react-i18next';
import { Flex, Grid, Text, Paper, Box } from '@mantine/core';
import { RadioButtonCard } from '../../components/RadioButtonCard/RadioButtonCard';
import { useFormContext } from '../../hooks/useFormContext';
import { productType } from '../../api/requests';
import { ButtonBar } from '../../components/Buttons/ButtonBar';
import { useUpdateProject } from '../../hooks/useUpdateProject';
import { useEffect } from 'react';
import { useGoToDashboard } from '../../hooks/useGoToDashboard';
import { useNavigateWithSearchParams } from '../../hooks/useNavigateWithSearchParams';
import { useCreateProject } from '../../hooks/useCreateProject';
import { InputCompact } from '../../components/Input/InputCompact';
import { RedStart } from './StepTwo';

export const StepOne = () => {
  const { t } = useTranslation();
  const { form } = useFormContext();
  const goToDashboard = useGoToDashboard();
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();
  const { createProject, isCreateProjectSuccess } = useCreateProject();
  const { updateProject, isUpdateProjectSuccess } = useUpdateProject();

  const handleProductTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('productType', event.target.value as productType);
  };

  const updateProjectForStepTwo = async () => {
    if (form.values.projectNumber) {
      await updateProject(form.values.projectNumber, { productType: form.values.productType });
    } else {
      const projectNumber = await createProject(form.values.name);
      form.setFieldValue('projectNumber', projectNumber);
      await updateProject(projectNumber, { productType: form.values.productType });
    }
    form.setFieldValue('productType', form.values.productType);
  };

  useEffect(() => {
    if (
      (form.values.projectNumber && isUpdateProjectSuccess) ||
      (!form.values.projectNumber && isCreateProjectSuccess && isUpdateProjectSuccess)
    ) {
      navigate(`/wizard/${form.values.projectNumber}/2`);
    }
  }, [isCreateProjectSuccess, isUpdateProjectSuccess]);

  const nameIsEmptyOrInvalid = !form.values.name || !!form.errors.name;
  const isNextButtonEnabledStepOne = form.values.productType && !nameIsEmptyOrInvalid;

  return (
    <>
      <Paper shadow="sm" radius="md" withBorder p="xl">
        <Grid gutter={40}>
          <Grid.Col span={9}>
            <Grid>
              <Grid.Col span={12}>
                <h2>{t('step-zero.header')}</h2>
                <Text style={{ marginTop: '10px' }}>
                  <RedStart /> {t('mandatory')}
                </Text>
              </Grid.Col>
              <Grid.Col span={12}>
                <Box w="300px">
                  <h3>{t('step-one.subtitle')}</h3>
                  <InputCompact
                    data-testid="name"
                    required
                    label={t('step-one.test-order-name')}
                    key={form.key('name')}
                    {...form.getInputProps('name')}
                  />
                </Box>
              </Grid.Col>
              <Grid.Col span={4}>
                <h3>
                  {t('step-one.chooseShippingProducts')} <span style={{ color: 'var(--BRAND-DHL-Red)' }}>*</span>
                </h3>
                <Flex gap="md">
                  <RadioButtonCard
                    w={150}
                    data-testid="dialogpost-radio-button-card"
                    label={t('DIALOGPOST')}
                    value="DIALOGPOST"
                    checked={form.values.productType === 'DIALOGPOST'}
                    onChange={handleProductTypeChange}
                  />
                  <RadioButtonCard
                    w={150}
                    data-testid="postwurfspezial-radio-button-card"
                    label={t('POSTWURFSPEZIAL')}
                    value="POSTWURFSPEZIAL"
                    checked={form.values.productType === 'POSTWURFSPEZIAL'}
                    onChange={handleProductTypeChange}
                  />
                </Flex>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={3}></Grid.Col>
        </Grid>
      </Paper>
      <ButtonBar
        onNext={updateProjectForStepTwo}
        onCancel={goToDashboard}
        cancelButtonLabel={t('cancel')}
        nextButtonLabel={t('next')}
        isNextButtonDisabled={!isNextButtonEnabledStepOne}
      />
    </>
  );
};
