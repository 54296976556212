import { Checkbox, CheckboxProps } from '@mantine/core';
import styled from '@emotion/styled';
import { CheckboxIcon } from '../Icons/CheckboxIcon';

export const CheckboxDefault = (props: CheckboxProps) => {
  return <CheckboxBase {...props} icon={CheckboxIcon} />;
};

const CheckboxBase = styled(Checkbox)<CheckboxProps>`
  --checkbox-color: var(--BRAND-DHL-Red) !important;
  --checkbox-size: 22px;
  --checkbox-radius: 4px;
  & .mantine-Checkbox-input {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 1px solid var(--GRAY-ALPHA-Gray-A-400);
    animation-timing-function: ease-in-out;
    animation-duration: 125ms;
    &:checked {
      border: 1px solid var(--BRAND-DHL-Red);
    }

    &:not(:disabled):not(:focus):hover {
      border: 1px solid var(--GRAY-OPAC-Gray-O-900);
    }
    &:focus {
      border: none;
      box-shadow: 0 0 0 2px var(--GRAY-OPAC-Gray-O-900) inset;
    }
    &:disabled {
      border: 1px solid var(--GRAY-ALPHA-Gray-A-100);
      background: var(--GRAY-ALPHA-Gray-A-100);
    }
    &:disabled:checked {
      border: none;
      background: var(--GRAY-ALPHA-Gray-A-100);
    }
  }
`;
