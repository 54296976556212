import { Navigate, NavigateOptions, NavigateProps, To, useLocation, useNavigate } from 'react-router-dom';

export const useNavigateWithSearchParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getSearchParamsWithoutPrefix = () => {
    const searchParams = new URLSearchParams(location.search);
    const filteredParams = new URLSearchParams();

    searchParams.forEach((value, key) => {
      if (!key.startsWith('ac-')) {
        filteredParams.set(key, value);
      }
    });

    return filteredParams;
  };

  const navigateAndKeepSearchParams = (to: To, options?: NavigateOptions) => {
    const searchParams = getSearchParamsWithoutPrefix();

    const newTo = typeof to === 'string' ? { pathname: to } : to;

    navigate(
      {
        ...newTo,
        search: `?${searchParams.toString()}`,
      },
      options,
    );
  };

  const NavigateAndKeepSearchParams = (props: NavigateProps) => {
    const searchParams = getSearchParamsWithoutPrefix();
    const to = typeof props.to === 'string' ? { pathname: props.to } : props.to;
    return (
      <Navigate
        {...props}
        to={{
          search: `?${searchParams.toString()}`,
          ...to,
        }}
      />
    );
  };
  const goToStep = (projectNumber: string, step: string) => () => {
    navigateAndKeepSearchParams(`/wizard/${projectNumber}/${step}`);
  };

  return { navigateAndKeepSearchParams, NavigateAndKeepSearchParams, goToStep };
};
