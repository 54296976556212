import { useTranslation } from 'react-i18next';
import { useNavigateWithSearchParams } from '../../hooks/useNavigateWithSearchParams';
import { useHover } from '@mantine/hooks';
import { PrimaryButton } from './Buttons';
import { PlusIcon } from '../Icons/PlusIcon';

export const StartNewCheckButton = () => {
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();

  const { t } = useTranslation();
  const { hovered, ref } = useHover();

  const goToWizard = () => {
    navigate('/wizard/');
  };

  return (
    <PrimaryButton
      data-testid="start-new-check-button"
      ref={ref}
      style={{ height: '36px', fontSize: '14px' }}
      leftSection={<PlusIcon color={hovered ? 'var(--BRAND-DHL-Red)' : 'white'} />}
      onClick={goToWizard}
    >
      {t('dashboard.start-new-check')}
    </PrimaryButton>
  );
};
