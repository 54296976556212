import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDomain } from '../../hooks/useEnv';

interface HelpButtonProps {
  page: string;
  children: React.ReactNode;
}

export const HelpButton: React.FC<HelpButtonProps> = ({ page, children }) => {
  const { domain } = useDomain();
  const context = '/geschaeftskunden/portal/kundendesktop/online-hilfe/automationscheck/';
  const { i18n } = useTranslation();

  const url = `${domain}${i18n.language}${context}${page}`;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};
