import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { WizardPageContainer } from './../components/WizardPageContainer';
import { FormContextProvider } from './../hooks/useFormContext';
import { Dashboard } from './../views/Dashboard';
import { User } from './../views/user';
import { DevTools } from './../components/DevTools/DevTools';
import { useMemo } from 'react';
import { StepOne } from '../views/steps/StepOne';
import { StepTwo } from '../views/steps/StepTwo';
import { StepThree } from '../views/steps/StepThree';
import { StepFour } from '../views/steps/StepFour';
import { StepFive } from '../views/steps/StepFive';
import { StepSix } from '../views/steps/StepSix';

const isMicroFrontend = process.env.MIFO;

export const externalRoutes = [
  {
    path: '/',
    element: <Navigate to="/wizard" replace />,
  },
  {
    path: '/wizard/:projectNumber?',
    element: (
      <FormContextProvider>
        <WizardPageContainer>
          <Outlet />
        </WizardPageContainer>
      </FormContextProvider>
    ),
    children: [
      {
        index: true,
        path: '*',
        element: <Navigate to="1" replace />,
      },
      {
        path: '1',
        element: <StepOne />,
      },
      {
        path: '2',
        element: <StepTwo />,
      },
      {
        path: '3',
        element: <StepThree />,
      },
      {
        path: '4',
        element: <StepFour />,
      },
      {
        path: '5',
        element: <StepFive />,
      },
      {
        path: '6',
        element: <StepSix />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <>
        <DevTools />
        <Dashboard />
      </>
    ),
  },
  ...(!isMicroFrontend
    ? [
        {
          path: '/user',
          element: <User />,
        },
      ]
    : []),
];

export const useRoutes = (): { internalRoutes: RouteObject[]; externalRoutes: RouteObject[] } => {
  const internalRoutes = useMemo(
    () =>
      externalRoutes.map((route, index) =>
        index === 1 ? { ...route, children: route.children?.filter((child) => child.path === '6') } : route,
      ),
    [],
  );

  return { internalRoutes, externalRoutes };
};
