import { useProjectControllerServiceGetProject, useProjectControllerServiceUpdateProject } from '../api/queries';
import { ProjectUpdateRequest } from '../api/requests';
import { useFormContext } from './useFormContext';

export const useUpdateProject = () => {
  const { form } = useFormContext();
  const updateProjectQueryInfo = useProjectControllerServiceUpdateProject();

  const getProjectQueryInfo = useProjectControllerServiceGetProject(
    { projectNumber: form.values.projectNumber ?? '' },
    undefined,
    {
      enabled: false,
    },
  );

  const updateProject = async (projectNumber: string, data: Partial<ProjectUpdateRequest>) => {
    await updateProjectQueryInfo.mutateAsync({
      projectNumber,
      requestBody: data,
    });
    return getProjectQueryInfo.refetch();
  };

  return {
    updateProject,
    isUpdateProjectSuccess: updateProjectQueryInfo.isSuccess,
  };
};
