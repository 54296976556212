import { useFormContext } from './useFormContext';

export const useWizardProgress = () => {
  const { form } = useFormContext();

  const isStep1Valid = form.values.projectNumber !== '';

  const isStep2Valid = isStep1Valid && form.values.productType !== null;

  const isStep3Valid = isStep2Valid && form.values.letterWindowType !== null;

  const isStep4Valid =
    isStep3Valid && form.values.letterFrankingPlacementType !== null && form.values.letterSenderPlacementType !== null;

  const isStep5Valid = isStep4Valid && form.values.userFileName !== '';
  const isStep5ValidForDialogPost = form.values.productType === 'DIALOGPOST' && isStep5Valid;
  const isStep5ValidForPostwurfspezial =
    form.values.productType === 'POSTWURFSPEZIAL' &&
    isStep5Valid &&
    (form.values.partialAddressRecipient !== '' || form.values.checkType === 'LAYOUT_CHECK');

  const getCurrentStepFromForm = () => {
    if (isStep5ValidForDialogPost || isStep5ValidForPostwurfspezial) {
      return 6;
    }

    if (isStep4Valid) {
      return 5;
    }
    if (isStep3Valid) {
      return 4;
    }
    if (isStep2Valid) {
      return 3;
    }
    if (isStep1Valid) {
      return 2;
    }
    return 1;
  };

  return {
    currentStepFromForm: getCurrentStepFromForm(),
  };
};
