// generated with @7nohe/openapi-react-query-codegen@1.6.1 

import { UseQueryResult } from "@tanstack/react-query";
import { ProductsProxyControllerService, ProjectControllerService } from "../requests/services.gen";
export type ProjectControllerServiceGetProjectsDefaultResponse = Awaited<ReturnType<typeof ProjectControllerService.getProjects>>;
export type ProjectControllerServiceGetProjectsQueryResult<TData = ProjectControllerServiceGetProjectsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useProjectControllerServiceGetProjectsKey = "ProjectControllerServiceGetProjects";
export const UseProjectControllerServiceGetProjectsKeyFn = ({ active, attestationState, checkType, createdByFamilyName, name, page, pageSize, productType, projectNumber, sortBy, sortDirection, state, updatedAfter, updatedBefore, xDelegatedEkp }: {
  active?: boolean;
  attestationState?: "INITIAL" | "FAILED" | "MALICIOUS" | "SUCCEEDED";
  checkType?: "FULL_CHECK" | "LAYOUT_CHECK";
  createdByFamilyName?: string;
  name?: string;
  page?: number;
  pageSize?: number;
  productType?: "DIALOGPOST" | "POSTWURFSPEZIAL" | "BRIEF" | "POSTKARTE" | "WERBEANTWORT" | "RESPONSEPLUS" | "WARENSENDUNG";
  projectNumber?: string;
  sortBy: "CREATED" | "NAME" | "PRODUCT_TYPE" | "PROJECT_STATE" | "UPDATED";
  sortDirection?: "ASC" | "DESC";
  state?: "FAILED" | "PENDING" | "RUNNING" | "DONE" | "CLOSED" | "EXPIRED";
  updatedAfter?: string;
  updatedBefore?: string;
  xDelegatedEkp?: string;
}, queryKey?: Array<unknown>) => [useProjectControllerServiceGetProjectsKey, ...(queryKey ?? [{ active, attestationState, checkType, createdByFamilyName, name, page, pageSize, productType, projectNumber, sortBy, sortDirection, state, updatedAfter, updatedBefore, xDelegatedEkp }])];
export type ProjectControllerServiceGetProjectDefaultResponse = Awaited<ReturnType<typeof ProjectControllerService.getProject>>;
export type ProjectControllerServiceGetProjectQueryResult<TData = ProjectControllerServiceGetProjectDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useProjectControllerServiceGetProjectKey = "ProjectControllerServiceGetProject";
export const UseProjectControllerServiceGetProjectKeyFn = ({ projectNumber, xDelegatedEkp }: {
  projectNumber: string;
  xDelegatedEkp?: string;
}, queryKey?: Array<unknown>) => [useProjectControllerServiceGetProjectKey, ...(queryKey ?? [{ projectNumber, xDelegatedEkp }])];
export type ProjectControllerServiceGetPreviewDefaultResponse = Awaited<ReturnType<typeof ProjectControllerService.getPreview>>;
export type ProjectControllerServiceGetPreviewQueryResult<TData = ProjectControllerServiceGetPreviewDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useProjectControllerServiceGetPreviewKey = "ProjectControllerServiceGetPreview";
export const UseProjectControllerServiceGetPreviewKeyFn = ({ projectNumber, quality, xDelegatedEkp }: {
  projectNumber: string;
  quality?: number;
  xDelegatedEkp?: string;
}, queryKey?: Array<unknown>) => [useProjectControllerServiceGetPreviewKey, ...(queryKey ?? [{ projectNumber, quality, xDelegatedEkp }])];
export type ProjectControllerServiceGetLatestAnalyzeResultsDefaultResponse = Awaited<ReturnType<typeof ProjectControllerService.getLatestAnalyzeResults>>;
export type ProjectControllerServiceGetLatestAnalyzeResultsQueryResult<TData = ProjectControllerServiceGetLatestAnalyzeResultsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useProjectControllerServiceGetLatestAnalyzeResultsKey = "ProjectControllerServiceGetLatestAnalyzeResults";
export const UseProjectControllerServiceGetLatestAnalyzeResultsKeyFn = ({ projectNumber, xDelegatedEkp }: {
  projectNumber: string;
  xDelegatedEkp?: string;
}, queryKey?: Array<unknown>) => [useProjectControllerServiceGetLatestAnalyzeResultsKey, ...(queryKey ?? [{ projectNumber, xDelegatedEkp }])];
export type ProjectControllerServiceGetAttestationDefaultResponse = Awaited<ReturnType<typeof ProjectControllerService.getAttestation>>;
export type ProjectControllerServiceGetAttestationQueryResult<TData = ProjectControllerServiceGetAttestationDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useProjectControllerServiceGetAttestationKey = "ProjectControllerServiceGetAttestation";
export const UseProjectControllerServiceGetAttestationKeyFn = ({ projectNumber, xDelegatedEkp }: {
  projectNumber: string;
  xDelegatedEkp?: string;
}, queryKey?: Array<unknown>) => [useProjectControllerServiceGetAttestationKey, ...(queryKey ?? [{ projectNumber, xDelegatedEkp }])];
export type ProductsProxyControllerServiceGetProductInfoDefaultResponse = Awaited<ReturnType<typeof ProductsProxyControllerService.getProductInfo>>;
export type ProductsProxyControllerServiceGetProductInfoQueryResult<TData = ProductsProxyControllerServiceGetProductInfoDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useProductsProxyControllerServiceGetProductInfoKey = "ProductsProxyControllerServiceGetProductInfo";
export const UseProductsProxyControllerServiceGetProductInfoKeyFn = ({ xDelegatedEkp }: {
  xDelegatedEkp?: string;
} = {}, queryKey?: Array<unknown>) => [useProductsProxyControllerServiceGetProductInfoKey, ...(queryKey ?? [{ xDelegatedEkp }])];
export type ProjectControllerServiceCreateProjectMutationResult = Awaited<ReturnType<typeof ProjectControllerService.createProject>>;
export type ProjectControllerServiceUpdateProjectMutationResult = Awaited<ReturnType<typeof ProjectControllerService.updateProject>>;
export type ProjectControllerServiceUploadDocumentMutationResult = Awaited<ReturnType<typeof ProjectControllerService.uploadDocument>>;
export type ProjectControllerServiceCopyProjectMutationResult = Awaited<ReturnType<typeof ProjectControllerService.copyProject>>;
export type ProjectControllerServiceCloseProjectMutationResult = Awaited<ReturnType<typeof ProjectControllerService.closeProject>>;
export type ProjectControllerServicePerformCheckMutationResult = Awaited<ReturnType<typeof ProjectControllerService.performCheck>>;
export type ProjectControllerServiceArchiveProjectMutationResult = Awaited<ReturnType<typeof ProjectControllerService.archiveProject>>;
