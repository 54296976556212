import { ChangeEvent, FocusEventHandler, KeyboardEventHandler, ReactNode } from 'react';
import { Card, CardProps, Text, Tooltip } from '@mantine/core';
import styled from '@emotion/styled';

interface RadioButtonCardProps extends CardProps {
  label: string;
  description?: string;
  image?: ReactNode;
  value: string;
  checked: boolean;
  cardDisabled?: boolean;
  disabledReason?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'cardDisabled' && prop !== 'disabledReason',
})<RadioButtonCardProps>`
  border: ${(props) =>
    props.checked ? '2px solid var(--GRAY-OPAC-Gray-O-600)' : '2px solid var(--GRAY-OPAC-Gray-O-500)'};
  background-color: ${(props) => (props.checked ? 'var(--GRAY-OPAC-Gray-O-400)' : 'var(--BASIC-White)')};
  border-radius: 10px;
  opacity: ${(props) => (props.cardDisabled ? 0.4 : 1)};

  &:hover,
  &:focus {
    border: ${(props) =>
      props.cardDisabled ? '2px solid var(--GRAY-OPAC-Gray-O-500)' : '2px solid var(--BRAND-DHL-Red)'};
    cursor: ${(props) => (props.cardDisabled ? 'not-allowed' : 'pointer')};
  }

  & .mantine-Card-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const RadioButtonCard = (props: RadioButtonCardProps) => {
  const handleClick = () => {
    if (props.cardDisabled) return;
    const event = {
      target: {
        value: props.value,
        checked: !props.checked,
      },
    } as ChangeEvent<HTMLInputElement>;
    props.onChange(event);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (props.cardDisabled) return;
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleClick();
    }
  };

  const handleFocus: FocusEventHandler<HTMLDivElement> = (event) => {
    const styledCard = event.currentTarget.querySelector('div');
    if (styledCard) {
      styledCard.setAttribute('tabindex', '0');
      styledCard.focus();
    }
  };

  return (
    <div onClick={handleClick} tabIndex={props.cardDisabled ? -1 : 0} onKeyDown={handleKeyDown} onFocus={handleFocus}>
      <Tooltip.Floating
        multiline
        w={300}
        label={props.cardDisabled ? props.disabledReason : ''}
        disabled={!props.cardDisabled}
        style={{
          backgroundColor: 'var(--GRAY-OPAC-Gray-O-100)',
          color: 'var(--BASIC-Black)',
          border: '2px solid var(--BRAND-DHL-Red)',
        }}
      >
        <StyledCard {...props} shadow="xs">
          <input
            type="radio"
            value={props.value}
            checked={props.checked}
            onChange={props.onChange}
            style={{ display: 'none' }}
            disabled={props.cardDisabled}
          />
          {props.image ? <Card.Section>{props.image}</Card.Section> : null}

          <Text fz="16" size="md" py={props.image ? 0 : 10} fw={700} ta="center" c="var(--BASIC-Black)">
            {props.label}
          </Text>

          {props.description && (
            <Text size="sm" mt="xs" c="var(--GRAY-OPAC-Gray-O-600)">
              {props.description}
            </Text>
          )}
        </StyledCard>
      </Tooltip.Floating>
    </div>
  );
};
